import React, { Component } from "react"
import Layout from "../components/Layout/layout"

class AboutPage extends Component {
  render() {
    let aboutData = this.props.data.allAboutJson.nodes[0]
    let teamMembers = []
    let i = 0
   
    return (
      <Layout page={"about"}>
        <div className="main">
          <h1 className="title">{aboutData.title}</h1>
          <div className="text">{aboutData.text}</div>
        </div>
      </Layout>
    )
  }
}
export default AboutPage

export const query = graphql`
  query {
    allAboutJson {
      nodes {
        id
        title
        description
        text
        subtitle
        name_icon
        title_icon
        mail_icon
        twitter_icon
        team_members {
          name
          title
          email
          twitter
          image
        }
      }
    }
  }
`
